
import { Link } from 'gatsby';
import {Helmet} from "react-helmet";
import Layout from "../components/layout";
//import 'react-awesome-slider/dist/styles.css';
// import 'react-awesome-slider/src/styles.js';
import { Title, Twitter, Testonimal } from "../components/Globals";
import { FaAngleDoubleDown } from "react-icons/fa";
import React, { useState } from 'react';
import Modal from 'react-modal';

const FintechPage = () => {

  const [modalIsOpen, setModalIsOpen] = useState(false);

    const ref = React.createRef();
  
    const arrowClick = () =>
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });

      const handleSubmit = (event) => {
        event.preventDefault();
        let headers = new Headers();
        headers.append('Authorization', 'Basic ' + btoa('api' + ":" + '5d2220e3b61e542f83de699a4ece81ad-4d640632-9b883e3e'));
        let formData = new FormData();
        formData.append('from', event.target.email.value);
        formData.append('to', 'sandip@cymetrixsoft.com');
        formData.append('subject', 'Downloading FinTech CaseStudy');
        formData.append('text', 'Name: '+event.target.name.value);
        formData.append('text', 'Contact: '+event.target.contact.value);
        fetch('https://api.mailgun.net/v3/sandbox0f7d801b76fb45bba72ceff5856c284d.mailgun.org/messages', {
          method: 'post',
          headers: headers,
          body: formData,
        })
        .then(function (data) {
          if(data.status === 200) {
         alert("CaseStudy will be opened in other tabs.");
         window.open(require('../pdfdownload/Cymetrix-CustomerSuccessStory-BFS.pdf')) && window.open(require('../pdfdownload/Cymetrix-CustomerSuccessStory-BFS-2.pdf'));
    
          }
        })
        .catch(function (error) {
          console.log('Request failed', error);
        });
      } 
      

    return (
      <Layout>
        <div className="infographics-background flex flex-col">
          <Helmet>
            <title>Infographics | Cymetrix Software</title>
            <meta name="description" content="Check out Salesforce, Data Analytics, Marketing Automation and other technology related infographics by Cymetrix Software."/>
            <meta name="keywords" content="salesforce infographics, data analytics infographics, india technology infographics"/>
            <link rel="canonical" href="https://www.cymetrixsoft.com/infographics/" />
          </Helmet>
          <h1 className=" xl:text-4xl xl:m-30 md:m-16 sm:m-24 lg:text-4xl md:text-3xl sm:text-3xl sma:text-xl text-white text-uppercase text-center">
            Infographics
          </h1>
          
          <button class="focus:outline-none " >
            <FaAngleDoubleDown className=" w-32 h-20 py-5 text-white" onClick={arrowClick}/>
          </button>
        </div>


        
        <div className="mx-0 flex justify-center">
          <div className="relative max-w-6xl mx-auto  mb-10 sma:mx-3">
            <div class="flex flex-col sm:flex-row sm:-mx-2 mt-10">
              <div class="mt-4 sm:w-3/3">
                <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                  
                  <div className="mt-2 text-base text-gray-700 text-left">How AI and other tech trends are transforming customer expectations in India?
                  <p> <Link to="/infographics/salesforce-report-indian-customers/" class="underline text-blue-700"> View Infographic</Link></p>
                  </div>
                </div>
              </div>
              



            </div>
            
          </div>
        </div>

       <div className="flex flex-row items-center justify-center  mt-4 sm:ml-10">
         
             
       <div className="px-16 py-10 bg-gray-400 text-gray-700 max-w-6xl mx-auto">
                <h4 class="text-lg"><b>Unlock the power of Customer 360</b></h4>
                <p> <Link to="/contact-us" class="underline text-blue-700"> Contact us</Link> now to explore how Cymetrix can transform your business.</p>
        </div>

<Modal  isOpen={modalIsOpen} onRequestClose={()=> setModalIsOpen(false)}

  style={{

    overlay: {
      overflow:'auto',
      position: 'fixed',
      top: 50,
      left: 300,
      right: 300,
      bottom: -20,
      
    
    }
    
  }}
  
>
<h1 className="text-lg  px-5 font-bold uppercase text-center" style={{color: '#1e407b'}}>Enter Details To Download CaseStudy</h1>
<main className="bg-transparent w-full flex flex-col items-center">
        <div className="max-w-5xl mx-4 ">
        <form className="justify-center flex" onSubmit={handleSubmit}>
                <div className="bg-white rounded px-8 pt-6 pb-8 mb-4 flex flex-col max-w-6xl sm:w-10/12">
                
                <div className="-mx-3 md:flex mb-6">
                    <div className="md:w-full">
                   
                    <label className="uppercase tracking-wide text-black text-xs font-bold mb-2" htmlFor="email">
                        Enter Your Email Id*
                    </label>
                    <input className="w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 mb-1"
                            id="email" type="text" placeholder="" name="email" required/>
                    </div>
                </div>
                <div className="-mx-3 md:flex mb-6">
                    <div className="md:w-full">
                    <label className="uppercase tracking-wide text-black text-xs font-bold mb-2" htmlFor="name">
                        Enter Your Name*
                    </label>
                    <input className="w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 mb-1"
                            id="name" type="text" placeholder="" name="name" required/>
                    </div>
                </div>
                <div className="-mx-3 md:flex mb-6">
                    <div className="md:w-full">
                    <label className="uppercase tracking-wide text-black text-xs font-bold mb-2" htmlFor="contact">
                        Enter Your Contact No.
                    </label>
                    <input className="w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 mb-1"
                            id="contact" type="text" placeholder="" name="contact" />
                    </div>
                </div>
                <div className="-mx-3 md:flex">
                    <div className="sma:w-full">
                    
                   <button  className="sma:w-full bg-header text-white font-bold py-2 px-4 mb-3 border-b-4 hover:border-b-#263f5f2 border-gray-500 hover:border-gray-100 uppercase rounded-full" type="submit" > Submit</button>

                   <button className=" sma:w-full bg-transparent text-center hover:border-blue-500 text-black text-base font-bold w-24 py-2 px-2 border border-gray-500 hover:border-transparent uppercase rounded-lg" onClick={() => setModalIsOpen(false)} >Close</button>
                    </div>
                </div>
                </div>
            </form>
        </div>
    </main>
   
</Modal>

          </div>
        <Testonimal />

      </Layout>
    );
}

export default FintechPage;